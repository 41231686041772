<template>
  <div id="result-scores">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="schoolClasses" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          schoolClasses.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Female" v-slot="props">{{
          props.row.female
        }}</b-table-column>

        <b-table-column label="Male" v-slot="props">{{
          props.row.male
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            :show-update="false"
            :show-remove="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '../../assets/js/app_info'

export default {
  name: 'AssignedClasses',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Assigned Classes',
      skipQuery: true,
      user: null,
      schoolClassId: null,
      schoolClass: null,
      schoolClasses: [],
      resultScores: [],
      assignedSchoolClasses: [],
    }
  },
  watch: {
    assignedSchoolClasses(data) {
      this.schoolClasses = data.schoolClasses
    },
  },
  methods: {
    initializeInfo(school_class) {
      this.schoolClass = school_class
      this.$router.push(`/school/${this.schoolId}/students/${school_class.id}`)
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id

      this.$store.commit('setSubMenus', [
        {
          name: 'New Ate',
          route: `/school/${user.school_id}/new_ate`,
        },
        {
          name: 'Ate Unmarked',
          route: `/school/${user.school_id}/ate_unmarked`,
        },
      ])

      this.$apollo.addSmartQuery('assignedSchoolClasses', {
        query: gql`
          query assignedSchoolClassesQuery($id: ID!) {
            assignedSchoolClasses(id: $id) {
              schoolClasses {
                id
                name
                female
                male
              }
            }
          }
        `,
        variables: {
          id: user.id,
        },
      })

      this.$apollo.queries.assignedSchoolClasses.refetch()
    })
  },
}
</script>

<style scoped></style>
